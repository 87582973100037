import React from 'react'
import filtr_punkt from '../../images/ikony/filtr_punkt.svg'

class FiltrButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            filtrAktywny: false,
            clear: this.props.clear,
        }
    }

    handleChange = (event) => {
        this.props.filtrAktywny(this.props.usluga);
        this.setState({
            filtrAktywny: !this.state.filtrAktywny
        })  
    }

   componentDidUpdate() {
  
        if(this.props.clear === true && this.state.filtrAktywny === true) {
            this.setState({
                filtrAktywny: false,
            })
        }
      
    }

 
    

    render() {
        console.log(this.props.clear)
        if(this.props.clear === false) {
          
            
            return(
                <li className={
                    this.state.filtrAktywny ? "filtr-aktywny" : ""
                    } onClick={this.handleChange} data-usluga={this.props.usluga} ref={(ref) => this.filtr = ref}>
                        
                        <div className="filtr-oznaczenie">
                            <img src={filtr_punkt} />
                        </div>

                        <p>{this.props.usluga}</p>
                </li>
            ) 
        }
        else {
             
            return(
               
                <li onClick={this.handleChange} data-usluga={this.props.usluga} ref={(ref) => this.filtr = ref}>
                        <div className="filtr-oznaczenie">
                            <img src={filtr_punkt} />
                        </div>
                        <p>{this.props.usluga}</p>
                </li>
            ) 
        }
        
    }

}


export default FiltrButton