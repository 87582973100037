import React, { useState } from 'react'

import { motion } from "framer-motion"

import Bread from '../bread'
import ButtonPaluch from '../buttons/button_goto'
import FiltrButton from './projekty_filtr'
import paluch from '../../images/ikony/paluch.svg'
import Seo from '../seo.js'
import Formularz from '../formularz/formularz'
import ProjektHeader from '../projekty/projekt_header'
import filtr from '../../images/ikony/filtr.svg'
import projekty from '../../images/seo/projekty.jpg'
import zamknij from '../../images/ikony/zamknij_male.svg'

import style from '../../pages/projekty.css'
import { render } from 'enzyme';
import TransitionLink, {TransitionState} from 'gatsby-plugin-transition-link'

var filtracja = []
const items = []

const title = 'PROJEKTY'


class Projekty extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      chowajProjekty: false,
      filtrPokaz: false,
      projekty: [],
      post_filtr: [],
      projekty_off: [],
      filtracja: [],
      filtry_on: false,
      clear: false,
      zakres_final: [],
    }
    this.handleChildClick = this.handleChildClick.bind(this)

  }




  

  

  handleChildClick = () => {
    this.setState({
      chowajProjekty: !this.state.chowajProjekty
    })
  }


 
  pokazFiltry = () => {
    this.setState({
      filtrPokaz: !this.state.filtrPokaz
    })
  }

  componentDidMount() {
    const zakres = []
    console.log("mountuje");
    const zakres_array = this.props.allProjektyJson.nodes.map((projekt) =>
      zakres.concat(projekt.zakres)
    )
    const zakres_full = [].concat.apply([], zakres_array);
    const zakres_final1 = Array.from(new Set(zakres_full));
    this.setState({
      projekty: this.props.allProjektyJson.nodes,
      zakres_final: zakres_final1,
    })


  
   


  }


  filtruj = () => {
    if(filtracja.length < 1){
      this.setState({
        projekty: this.props.allProjektyJson.nodes,
        projekty_off: [],
        filtracja: [],
       filtry_on: false,
      
      })
      this.clearFiltry();
    }
   
    else {
      const post_filtr_ar = this.props.allProjektyJson.nodes.filter(projekt => filtracja.every(r=> projekt.zakres.includes(r)))
      

      
      this.setState({
        projekty: post_filtr_ar,
        filtracja: filtracja,
        filtry: true,
        clear: false,
        filtry_on: true,
      }, () => {
        this.reszta();
    });
      console.log(post_filtr_ar);
      
      
      


      
    }
    
    

  }

  reszta = () => {
    const reszta = this.props.allProjektyJson.nodes.filter(val => !this.state.projekty.includes(val));


    this.setState({
      projekty_off: reszta
    })
  }
 
  dodajFiltr = (usluga) => {
    if(filtracja.indexOf(usluga) > -1) {
      filtracja = filtracja.filter(e => e !== usluga);
      console.log(filtracja);
      this.filtruj()
    }
    else {
      filtracja.push(usluga)
      console.log(filtracja);
      this.filtruj()
    }
    
  }

  filtry_switch = () => {
    this.setState({
      filtry_on: false,
      projekty: this.props.allProjektyJson.nodes,
      projekty_off: [],
      filtracja: [],
    })
    this.clearFiltry();
  }

  clearFiltry = () =>  {
    console.log("Clear Filtry");
    this.setState({
       clear: true,
    })
    filtracja = [];
  }
  
  


  

  render() {


    



    const variants = {
      visible: i => ({
        opacity: 1,
        y: 0,
        transition: {
          ease: "easeOut",
          delay: i * 0.3 + 0.6,
        },
      }),
      hidden: { opacity: 0, y: 50 },
    }



    

   
    

   
  
    return <>
  
    <motion.div 
     ref={this.check}
    className={this.state.chowajProjekty ? "projekty-chowaj" : ""}>
    <Seo title={title} description="Poznaj wybrane projekty, które realizowaliśmy w ostatnim czasie. Klikaj śmiało! Więcej naszych prac znajdziesz w mediach społecznościowych @odrastudio." image={projekty} />
    <Bread title={title} />


     



      <div className="projekty-filtry">
        <h6 onClick={this.filtruj}>Tworzymy niezapomniane marki</h6>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div id="filtry-wrapper" className={this.state.filtrPokaz ? "filtr-rozwin" : ""}>
            <div  id="filtr" className={this.state.filtry_on ? "filtry-aktywne" : ""}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={this.pokazFiltry}>
              <img style={{marginRight: '10px'}} src={filtr} />
              <span className="przycisk">FILTRUJ</span>
              </div>
              
              <img onClick={this.filtry_switch}  src={zamknij} />
            </div>
           
            <div id="filtr-lista">
              <div onClick={this.pokazFiltry} id="filtr" className={this.state.filtry_on ? "filtry-aktywne filtry-popup filtry-popup-activ" : "filtry-popup"}>
                <img src={filtr} />
                <span className="przycisk">FILTRUJ</span>
                <span onClick={this.filtry_switch} className="przycisk filtry-usun">USUŃ FILTRY</span>
                <img onClick={this.filtry_switch}  src={zamknij} />
              </div>
              <h6>Zawartość projektu</h6>
              <ul>
                {this.state.zakres_final.map((z, i) => (
                  <FiltrButton zakresAktywny={this.state.filtracja} key={i} clear={this.state.clear} filtrAktywny={this.dodajFiltr} onClick={this.handleChildClick} usluga={z} />
                ))}
                
              </ul>
              <div onClick={this.pokazFiltry}  className="przycisk-obramowanie">
                <img src={paluch} style={{marginRight: '10px'}} /><span className="przycisk">FILTRUJ</span>
              </div>
            </div>

            
          </div>

            <a style={{marginBottom: '2.5px'}} href="https://www.behance.net/odra" target="_blank">
              <span className="przycisk">POBIERZ PORTFOLIO</span>
            </a>
          </div>
      </div>      
        
      <div className="lista">
                  
        {this.state.projekty.map((p, i) => (
          <div
          key={p.klient.toString()}
          data-zakres={p.zakres}
          >
          <ProjektHeader
            key={i}
            klient={p.klient}
            dzialania={p.dzialania}
            zakres={p.zakres}
            zajawka={p.zajawka}
            data={p.data}
            img={p.url.childImageSharp.fluid}
            filtracja={this.state.filtracja}
            slug={p.slug}
            handleChildClick={this.handleChildClick}
              />
              
     
          </div>
        ))}


      {this.state.projekty_off.map((p, i) => (
          <div
          key={p}
          key={p.klient.toString()}
          data-zakres={p.zakres}
          className="projekt-nieaktywny"
          >
          <ProjektHeader
            klient={p.klient}
            dzialania={p.dzialania}
            zajawka={p.zajawka}
            zakres={p.zakres}
            data={p.data}
            img={p.url.childImageSharp.fluid}
            filtracja={this.state.filtracja}
            slug={p.slug}
            handleChildClick={this.handleChildClick}
              />
              
     
          </div>
        ))}
      </div>

    
    

      <Formularz />
      </motion.div>
  
    </>
        
}
}




export default Projekty

