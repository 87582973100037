import React from 'react'
import ProjektyPage from '../components/projekty/projekty'
import { useStaticQuery, graphql } from "gatsby"

export default function Projekty() {

  
  const data = useStaticQuery(graphql`
  query {
    allProjektyJson {
          nodes {
            id
            klient
            data
            slug
            url {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            zajawka
            zakres
          }
        }
      }
  `)
  return (<><ProjektyPage allProjektyJson={data.allProjektyJson} /></>)
}
